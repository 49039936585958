import React from "react"
import { Link, graphql } from "gatsby"
import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"
import MyLogo from "../components/Logo"
import MySignature from "../images/Signature.svg"

import "../Style/EssayPage.css"
import TitleUnderlined from "../components/TitleUnderlined"
import MyLogoFixed from "../components/LogoFixed"

const MBN = ({data}) => (
 <div> 
    <SEO title="MBN — Phil Murphy" />

    <Helmet>

        <link rel="subresource" href="../Style/EssayPage.css" />

        <meta name="twitter:description" content="A little analysis of my time spent creating a new version of MBN">
        </meta>

        <meta name="og:description" content="A little analysis of my time spent creating a new version of MBN">
        </meta>

        <meta name="description" content="A little analysis of my time spent creating a new version of MBN">
        </meta>

        <meta name="image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="twitter:image:alt" content="A little analysis of my time spent creating a new version of MBN">
        </meta>

        <meta name="thumbnail" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

        <meta name="og:image" content="https://phil.how/static/bd858f8d534be28f0237dbd6e2f0cccc/62657/PhilMurphy_CoverDefault.png">
        </meta>

    </Helmet>

    <div className="EssayPageWrapper">

        <essayheader><MyLogoFixed /></essayheader>

        <essaycontent>


        <p className="EssayTitlePage">MBN</p>

        <TitleUnderlined title="Introduction" color="green" />
        <p className="EssayContentPage">I joined the MBN team in early 2018 as a Product Designer when they were looking for someone who could help blueprint a complete refactoring of their existing tools.</p>

        <TitleUnderlined title="The Problem" color="red" />
        <p className="EssayContentPage">The idea behind MBN is to create a safe space for deals between traders and investors and allow them to specify certain conditions that must be met, otherwise the contract will be immediately terminated. </p>
        <p className="EssayContentPage">And although this can sound quite straightforward, one of the main problems I saw has been how much the project is unclear to many who are not already familiar with the cryptocurrency or other exchange platforms.</p>


        <TitleUnderlined title="So, what I wanted to do?" color="orange" />

        <p className="EssayContentPage">I adore a quote that <a href="https://twitter.com/brian_armstrong" target="_blank" rel="noopener">Brian Armstrong</a> once said:</p>

            <div className="QuoteBlockPurple">
            <div className="QuoteContent"><span className="QuoteHighlight">"We should always push to make our products easier to use. We avoid using technical jargon like "private key", "force quit", or "hash" in our products and when communicating with customers. Imagine a product so simple that anyone can use it, even if they are illiterate. We aim to educate our customers as they use our product, preparing them to benefit from the cryptoeconomy."</span></div>
            </div>

        <p className="EssayContentPage">Thus, my main focus was to make the interaction and purpose of the platform as simple as possible. So I started with collecting feedback...</p>

        <TitleUnderlined title="Feedback Collecting" color="yellow" />

        <p className="EssayContentPage">Since I was absent from the very beginning and I joined it when it already had some customers and community around, the first thing I decided was to collect feedback from all those who would be upset they would never be able to use the platform again.</p>

        <p className="EssayContentPage">And I'm not going to lie, the process of collecting feedback is my favorite step in the roadmap for creating any product. It's amusing, it feels great, and it's helpful as hell. Through 20-min or even text interviews with people who are really interested in improvements, I was able to learn a lot about the industry, product, and unsolved problems in just a few days. </p>

        <p className="EssayContentPage">And more importantly, each of the clients with whom I've had conversations — felt an incredible joy to share feedback with someone from the project team they love and just be genuinely heard. It's priceless.</p>

        <br />  
        <p className="EssayAddContentPage"><i>So what did I do with all that feedback and my vision?</i></p>

        
        <TitleUnderlined title="Dashboard" color="red" />
        <p className="EssayAddContentPage">Problem</p>
        <p className="EssayContentPage">One of the main tragedies has been the tabs overload and the incomprehensibility of the displayed information. So I started the redesign with the most inconvenient, but essential page — <b>Dashboard</b>.</p>
  
        <div className="EssayImagePage">
          <a href="../static/0c152c4c8fcd440c02c18d3f9325b1c6/57ea6/MBN1.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN1.childImageSharp.fluid}
            />
          </a>
        </div>

        

        <p className="EssayAddContentPage">Solution</p>
         <p className="EssayContentPage">Even though I thought about options that wouldn't change existing pages so much, it didn't work out.</p>
         
         <p className="EssayContentPage">After all, I came to the decision to combine and reduce some amount of tabs in favor of creating a single board of all contracts on which you could find all open and available offers from both investors and traders <i> — this was not previously possible, but the study showed that being able to search for investor too is an important feature for bulk of users</i>.</p>
         <p className="EssayContentPage">So this is how the <b>Contract Board</b> was made:</p>

         <div className="EssayImagePage">
          <a href="../static/b412edef0e703c52f9070c4736e6c7b9/d9bdf/MBN14.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN14.childImageSharp.fluid}
            />
          </a>
        </div>


         <TitleUnderlined title="Trader's Profile" color="red" />
        <p className="EssayAddContentPage">Problem</p>
        <p className="EssayContentPage">Another difficulty was honed in the epicenter and "heart" of the platform — <b>Trader's profiles</b> and the process of concluding contract itself. Just like the Dashboard, it wasn't intuitive enough and take too long to get to the final point.</p>

        <div className="EssayImagePage">
          <a href="../static/23a37b09f38f73578d6cfea6901c428e/243d6/MBN3.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN3.childImageSharp.fluid}
            />
          </a>
        </div>

        <p className="EssayAddContentPage">Solution</p>
        <p className="EssayContentPage">The solution was to create a slightly different arrangement of main components that now would focus on important information, as well as show all available offers that were put up on the global board.</p>

        <div className="EssayImagePage">
          <a href="../static/1802a31e6c48f089008af88f8a3972de/d9bdf/MBN15.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN15.childImageSharp.fluid}
            />
          </a>
        </div>

        <p className="EssayContentPage">As for personal profile, I've added the ability to manage all the exchange accounts in one single and comfortable place.</p>

        <div className="EssayImagePage">
          <a href="../static/d47fa2839f722d50387e4616dfdf191b/d9bdf/MBN16.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN16.childImageSharp.fluid}
            />
          </a>
        </div>

        <TitleUnderlined title="Contract Overview" color="red" />
        <p className="EssayAddContentPage">Problem</p>
        <p className="EssayContentPage">The entire contract flow was distributed across multiple pages (Dashboard → Trader's Profile). So many of those who tried to sign contracts barely found the information they needed (for example, about the trader's fee or how to pay it) and immediately got lost. </p>
        <p className="EssayAddContentPage"><i>i.e. after signing a contract on the trader's profile page, you have to head to Dashboard to pay a fee and see the current status (e.g. pending).</i></p>

        <div className="EssayImagePage">
          <a href="../static/3f5565db8e40c5249cb2043ffac934df/7c1aa/MBN6.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN6.childImageSharp.fluid}
            />
          </a>
        </div>

        <p className="EssayAddContentPage">Solution</p>
        <p className="EssayContentPage">Along with a completely new Contract Board, I've created a separate tab where users can see all active and completed contracts, as well as all the necessary information. </p>
        <p className="EssayContentPage">It's also felt right to add direct messages between traders and investors, as many wanted to have this ability to solve small inner problems (e.g. asking for a little bit different conditions, etc).</p>

        <div className="EssayImagePage">
          <a href="../static/63fec83acb5de042e499035994ce213f/d9bdf/MBN17.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN17.childImageSharp.fluid}
            />
          </a>
        </div>


        <p className="EssayContentPage">Each contract now also has its own separate page where users can see detailed statistics and all the necessary controls.</p>

        <div className="EssayImagePage">
          <a href="../static/2680cb9755d4686b399a277aec8ccad9/d9bdf/MBN18.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN18.childImageSharp.fluid}
            />
          </a>
        </div>


        <TitleUnderlined title="Trader's Search" color="red" />
        <p className="EssayAddContentPage">Problem</p>
        <p className="EssayContentPage">The last problem I worked on was the Trader Search page. In addition to being unintuitive, it was also endowed with unnecessary and impractical data (Let's say, Profit Calculator*)</p>
        <p className="EssayAddContentPage"><i>* "profit calculator" allowed you to find out how much you could have earned if you had invested in this trader some time ago</i></p>

        <div className="EssayImagePage">
          <a href="../static/11902f035c48f04ee74ada8c06dbf5b0/cf23e/MBN9.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN9.childImageSharp.fluid}
            />
          </a>
        </div>

        <p className="EssayAddContentPage">Solution</p>
        <p className="EssayContentPage">As you may remember :) I combined several previously existing pages into one and now the search for traders, as well as investors, is located on a single page called Contract Board</p>

        <div className="EssayImagePage">
          <a href="../static/b412edef0e703c52f9070c4736e6c7b9/d9bdf/MBN14.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN14.childImageSharp.fluid}
            />
          </a>
        </div>

        <p className="EssayContentPage">I also tried to improve the contract signing process which now let you to see the balance of any of your exchange accounts, and also allows you to conveniently edit various terms.</p>

        <div className="EssayImagePage">
          <a href="../static/c08edc162cd2fdd689b9c881ec3dc72b/d9bdf/MBN19.png" target="_blank" rel="noopener">
            <Img 
                fluid={data.MBN19.childImageSharp.fluid}
            />
          </a>
        </div>

        <div className="Contentnderline"></div>

        <div className="CautionBlock">
          <h2 className="CautionTitle">Thanks for scrolling down here!</h2>
          <div className="CautionContent">
          <div className="CautionStyle">While working on this project I've learned lots of pitfalls when dealing with dashboards built off vast data that can simultaneously confuse new users, but be potentially valuable to more advanced ones.</div>
          <div className="CautionStyle">So if you have any questions about crypto or dashboard designing process, you can shoot me a message <b>nerf@hey.com</b> or slide into my <a href="https://twitter.com/NERFWRLD" target="_blank" rel="noopener">Twitter DMs</a>.</div>
          </div>
        </div>


        </essaycontent>







        <essayfooter>
        <div>
          <div className="Signature"><img src={MySignature} width="166" /></div>
        </div>
        </essayfooter>

    </div>

    </div>
)

export default MBN

export const query = graphql`
  query {

    MBN1: file(relativePath: { eq: "MBN1.png" }) {
      childImageSharp {
        fluid(maxWidth: 4000, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    MBN2: file(relativePath: { eq: "MBN2.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      MBN3: file(relativePath: { eq: "MBN3.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      MBN4: file(relativePath: { eq: "MBN4.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      MBN5: file(relativePath: { eq: "MBN5.png" }) {
        childImageSharp {
          fluid(maxWidth: 4000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
  
      MBN6: file(relativePath: { eq: "MBN6.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
  
        MBN7: file(relativePath: { eq: "MBN7.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
  
        MBN8: file(relativePath: { eq: "MBN8.png" }) {
          childImageSharp {
            fluid(maxWidth: 4000, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }

        MBN9: file(relativePath: { eq: "MBN9.png" }) {
            childImageSharp {
              fluid(maxWidth: 4000, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
      
          MBN10: file(relativePath: { eq: "MBN10.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
      
            MBN11: file(relativePath: { eq: "MBN11.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN14: file(relativePath: { eq: "MBN14.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN15: file(relativePath: { eq: "MBN15.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN16: file(relativePath: { eq: "MBN16.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN17: file(relativePath: { eq: "MBN17.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN18: file(relativePath: { eq: "MBN18.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }

            MBN19: file(relativePath: { eq: "MBN19.png" }) {
              childImageSharp {
                fluid(maxWidth: 4000, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          

  }
`